import styled from 'styled-components';
import mainBackground from '../../assets/privacy_policy_bg.png';
import HtmlEmbed from '../../components/iframe/htmlEmbed';
import { useTranslation } from 'react-i18next';
import TermsAndConditions from '../TermsConditions/user';

const PrivatePolicy = ({refs}) => {
  const { i18n, t } = useTranslation('translation');
  let lang = i18n.language;
  return (
    <Wrapper>
      {lang=="tc"?  <HtmlEmbed src={'/privacy_policy_tc.html'}></HtmlEmbed> : <HtmlEmbed src={'/privacy_policy.html'}></HtmlEmbed>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  width: 80%;
  height: 100%;
  background: url(${mainBackground});
  background-size: contain;
  background-repeat: no-repeat;
  overflow: auto;
  padding: 0 10%;
`;

const Title = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color : black;
  margin-top: 200px;
`;

const SubTitle = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color : black;
`;

export default PrivatePolicy;
