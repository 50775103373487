import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import Hamburger from '../../assets/drawer_hamburger.svg';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import CmpLoginButton from '../../components/button/login';
import LanguageButton from '../../components/button/language';
import MediaQuery from "../../hook/MediaQuery";
import { useTranslation } from 'react-i18next';
import Drawer from "../drawer";


const Navbar = ({ refs }) => {
  const [drawer, setDrawer] = useState(false);
  const screenSize = MediaQuery().screenSize;
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation');

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoaded = () => { if (!isLoaded) setIsLoaded(true) }
  useEffect(() => {
    window.addEventListener('load', handleLoaded);
    return () => { window.removeEventListener('load', handleLoaded) }
  }, [])


  const languageChange = () => {
    let pathname = location.pathname;
    if (i18n.language == 'en') {
      i18n.changeLanguage('tc');
      let newPathname = pathname.replace('en', 'tc');
      navigate(`${newPathname}`);
    } else {
      i18n.changeLanguage('en');
      let newPathname = pathname.replace('tc', 'en');
      navigate(`${newPathname}`);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      switch (location.hash) {
        case "#aboutUs":
          scrollSmoothHandler(refs.aboutUsRef);
          break;
        case "#forUser":
          scrollSmoothHandler(refs.forUserRef);
          break;
        case "#forPractitioner":
          scrollSmoothHandler(refs.forPractitionerRef);
          break;
        case "#faq":
          scrollSmoothHandler(refs.faqRef);
          break;
        case "#award":
          scrollSmoothHandler(refs.awardRef);
          break;
        case "#download":
          scrollSmoothHandler(refs.downloadRef);
          break;

        default:
          window.scrollTo(0, 0);
          break;
      }
    }

  }, [location, refs, isLoaded]);

  const scrollSmoothHandler = ref => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {
        screenSize == "Desktop" ?
          <StyledNavbarContainer>
            <StyledContainerLogo to={`/${i18n.language}`}>
              <StyledImg src={logo} alt="logo"></StyledImg>
            </StyledContainerLogo>

            <StyledContainerItems>
              <StyledNavLink to={`/${i18n.language}/#aboutUs`}>{t('nav.aboutUs')}</StyledNavLink>
              <StyledNavLink to={`/${i18n.language}/#forUser`}>{t('nav.forUser')}</StyledNavLink>
              <StyledNavLink to={`/${i18n.language}/#forPractitioner`}>{t('nav.forPractitioner')}</StyledNavLink>
              <StyledNavLink to={`/${i18n.language}/#award`}>{t('nav.award')}</StyledNavLink>
              <StyledNavLink to={`/${i18n.language}/#faq`}>{t('nav.faq')}</StyledNavLink>
              <StyledNavLink to={`/${i18n.language}/#download`}>{t('nav.download')}</StyledNavLink>
              {/* <StyledNavLink to="/cmpLogin">
                <CmpLoginButton></CmpLoginButton>
              </StyledNavLink> */}
              <LanguageButton value={i18n.language} onClick={() => languageChange()}>
              </LanguageButton>
            </StyledContainerItems>
          </StyledNavbarContainer>
          :
          <StyledMobileNavbarContainer>
            <StyledContainerLogo to={`/${i18n.language}`}>
              <StyledMobileImg src={logo} alt="logo"></StyledMobileImg>
            </StyledContainerLogo>
            <div>
              <StyledHamburgerDiv onClick={() => setDrawer(true)}>
                <img src={Hamburger} style={{ height: '20px', cursor: 'pointer' }}></img>
              </StyledHamburgerDiv>
              <Drawer open={drawer} setDrawer={setDrawer}></Drawer>
            </div>
          </StyledMobileNavbarContainer>
      }
    </div>
  );
};

const StyledMobileNavbarContainer = styled.div`
  width: 100%;
  height: 100px;
  background: #2490C8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHamburgerDiv = styled.div`
  margin-right: 20px;
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
  margin-right: 30px;
  white-space: nowrap;
`;

const StyledNavbarContainer = styled.div`
  width: 100%;
  height: 100px;
  background: #2490C8;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const StyledContainerLogo = styled(NavLink)`
  margin: 10px 10%;
`;

const StyledContainerItems = styled.div`
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  margin: 10px 10% 0 0;
  width: 100%;
`;

const StyledMobileImg = styled.img`
  height: 58px;
  justify-content: left;
  display: flex;
`;

const StyledImg = styled.img`
  height: 68px;
  justify-content: left;
  display: flex;
`;

export default Navbar;
