import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

import TranslationEn from "./lang/en.json"
import TranslationTc from "./lang/tc.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    fallbackLng: 'en',
    ns: [
      'translation',
    ],
    resources:{
      en:{
        translation:TranslationEn
      },
      tc:{
        translation:TranslationTc
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
