import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import MediaQuery from "../../hook/MediaQuery";

const YoutubeEmbed = ({ embedId }) => {
  const screenSize = MediaQuery().screenSize;
  return (
  <StyledDiv>
    <StyledIframe
      width="80%"
      height= {screenSize == "Desktop"? "500" : "350"}
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </StyledDiv>
)};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

const StyledDiv = styled.div`
  position: relative;
  height: min-content;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
`;

const StyledIframe = styled.iframe`
  overflow: hidden;

  position: relative;
`;

export default YoutubeEmbed;