import React, { useEffect, useState } from 'react';
import BackToTop from "../../assets/back_to_top.svg";
import BackToTopTC from "../../assets/back_to_top_zh.png";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

function BackToTopButton() {
    const [offset, setOffset] = useState(0);
    const { i18n } = useTranslation('translation');
    let image = null;
    if(i18n.language == "tc"){
      image = BackToTopTC;
    } else{
      image = BackToTop;
    }

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
      <StyledDiv hidden={offset < 400}>
          <StyledImgDiv onClick={() => {window.scrollTo({
            top: 0,
            behavior: 'smooth',
            })}} src={image}>
        </StyledImgDiv>
      </StyledDiv>
    );
  }

const StyledDiv = styled.div`
    position: fixed;
    bottom: 9px;
    right: 10px;
`;

const StyledImgDiv = styled.img`
    height: 55px;
    width: 55px;
    cursor: pointer;
`;
  
  
  export default BackToTopButton;
  