import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from './pages/Home';
import PrivatePolicy from './pages/PrivatePolicy';
import UserTermsAndConditions from './pages/TermsConditions/user';
import PractitionerTermsAndConditions from './pages/TermsConditions/practitioner';
import { withNavBar } from './utils/withNavBar';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => { };
}

function App({ refs }) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

   
    if (location.pathname == "/") {
      navigate("/en")
    }
    if(location.pathname.includes("/escortRequest")){
      let newLanguage = lang
      if(lang !== 'en' && lang !== 'tc'){
        newLanguage = 'en'
      }
      navigate(`${newLanguage}/#download`)
    }
    if (location.pathname.includes("/tc")) {
      i18n.changeLanguage('tc');
    }
    if (location.pathname.includes("/en")) {
      i18n.changeLanguage('en');
    }
    
  }, [location]);


  

  return (
    <div style={{ height: '100%' }}>
      {
        lang == "en" &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>In Good Health</title>
          <meta property="og:title" content="In Good Health" />
          <meta property="og:description" content="Your Chinese Medicine telehealth platform" />
        </Helmet>
      }
      {
        lang == "tc" &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>開心醫館</title>
          <meta property="og:title" content="開心醫館" />
          <meta property="og:description" content="您的中醫遠距醫療平台" />
        </Helmet>
      }
      <Routes>
        <Route exact path={`/tc`} element={<Home refs={refs} />} />
        <Route exact path={`/tc/private_policy`} element={<PrivatePolicy refs={refs} />} />
        <Route exact path={`/tc/terms_conditions_user`} element={<UserTermsAndConditions refs={refs} />} />
        <Route exact path={`/tc/terms_conditions_practitioner`} element={<PractitionerTermsAndConditions refs={refs} />} />
        <Route exact path={`/en`} element={<Home refs={refs} />} />
        <Route exact path={`/en/private_policy`} element={<PrivatePolicy refs={refs} />} />
        <Route exact path={`/en/terms_conditions_user`} element={<UserTermsAndConditions refs={refs} />} />
        <Route exact path={`/en/terms_conditions_practitioner`} element={<PractitionerTermsAndConditions refs={refs} />} />
      </Routes>
    </div>
  );
}

export default withNavBar(App);
