import * as React from 'react';
import styled from 'styled-components';

export default function LanguageButton({value, ...props}) {
  return (
      <Button {...props}>
          <div style={{textDecoration: value.includes('en')? 'underline' : 'none'}}>Eng</div>
          <div>|</div>
          <div style={{textDecoration: value.includes('tc')? 'underline' : 'none'}}>中</div>
      </Button>
  );
}

const Button = styled.button`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    background: none;
    border: 0px;
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    width: 80px;
    cursor: pointer;
`;