import { useMediaQuery } from 'react-responsive';

const MediaQuery = () => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1199 });
    const isMobile = useMediaQuery({ maxWidth: 575 });

    return {
        screenSize: (isDesktop ? "Desktop" : (isTablet ? "Tablet" : (isMobile? "Mobile" : "Tablet")))
    }
}

export default MediaQuery;