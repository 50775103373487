import styled from 'styled-components';
import MediaQuery from '../../hook/MediaQuery';

const TextImgCard = function ({ image, title, text }) {
    const screenSize = MediaQuery().screenSize;
    return (
        <div>
            {
                screenSize == "Desktop" &&
                <StyledContainer>
                    <StyledCardTitle>{title}</StyledCardTitle>
                    <StyledCardText>{text}</StyledCardText>

                    <StyledImage src={image}></StyledImage>
                </StyledContainer>
            }
            {
                (screenSize == "Tablet" || screenSize == "Mobile") &&
                <StyledContainer>
                    <StyledCardTitle>{title}</StyledCardTitle>
                    <StyledCardText>{text}</StyledCardText>

                    <StyledImage src={image}></StyledImage>
                </StyledContainer>
            }
        </div>
    );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  height: 300px;
`;

const StyledCardTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
    text-transform: uppercase;
    color: #32394C;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 17px;
  width: 100%;
`;

const StyledCardText = styled.div`
    /* font-family: SF Pro Display; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #5F6872;
`;

export default TextImgCard;
