import styled from 'styled-components';
import MediaQuery from '../../hook/MediaQuery';

const AboutUsCard = function({image, title, text}) {
  const screenSize = MediaQuery().screenSize;
    return (
      <div>
        {
          screenSize == "Desktop" &&
          <StyledMaxContainer>
            <StyledImageContainer>
                <StyledImage src={image}></StyledImage>
            </StyledImageContainer>

            <StyledCardTitle>{title}</StyledCardTitle>
            <StyledCardText>{text}</StyledCardText>
          </StyledMaxContainer>
        }
        {
          (screenSize == "Tablet" || screenSize == "Mobile") &&
          <StyledSmallContainer>
            <StyledImageContainer>
                <StyledImage src={image}></StyledImage>
            </StyledImageContainer>

            <StyledCardTitle>{title}</StyledCardTitle>
            <StyledCardText>{text}</StyledCardText>
          </StyledSmallContainer>
        }
      </div>
    );
}

//---------------For Desktop----------------
const StyledMaxContainer = styled.div`
  background: #F5F5F5;
  width: 307px;
  height: 252px;
  border-radius: 30px;
  padding: 17px;
  margin: 10px;
`;

//------------------------------------------

//---------------For Mobile----------------
const StyledSmallContainer = styled.div`
  margin-bottom: 100px;
  width: 290px;
  height: 260px;
  background: #F5F5F5;
  border-radius: 30px;
`;

//------------------------------------------

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

const StyledImage = styled.img`
  margin-top: -100px;
  height: 200px;
  width: 200px;
  object-fit: contain;
`;

const StyledCardTitle = styled.div`
    /* font-family: SF Pro Display; */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 15px;
    padding: 0px 30px 0px 30px;
    margin-top: -15px;
    color: #5F6872;
`;

const StyledCardText = styled.div`
    /* font-family: SF Pro Display; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0px 30px 30px 30px;
    margin-top: 0px;
    color: #5F6872;
`;

export default AboutUsCard;
