import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function Footer() {
  const screenSize = MediaQuery().screenSize;
  const { i18n, t } = useTranslation('translation');
 
    return (
      screenSize == "Desktop"?
        <StyledContainer>
          <StyledInnerContainer>
            <StyledLink to={`/${i18n.language}/terms_conditions_user`}>{t('footer.tncUser')}</StyledLink>
            <StyledLink to={`/${i18n.language}/terms_conditions_practitioner`}>{t('footer.tncPractitioner')}</StyledLink>
            <StyledLink to={`/${i18n.language}/private_policy`}>{t('footer.privacyPolicy')}</StyledLink>
          </StyledInnerContainer>
          <div>
            <StyledCopyright>{t('footer.copyright')}</StyledCopyright>
          </div>
        </StyledContainer>
        :
        <StyledSmallContainer>
          <StyledInnerSmallContainer>
            <StyledSmallLink to={`/${i18n.language}/terms_conditions_user`}>{t('footer.tncUser')}</StyledSmallLink>
            <StyledSmallLink to={`/${i18n.language}/terms_conditions_practitioner`}>{t('footer.tncPractitioner')}</StyledSmallLink>
            <StyledSmallLink to={`/${i18n.language}/private_policy`}>{t('footer.privacyPolicy')}</StyledSmallLink>
          </StyledInnerSmallContainer>
          <div>
            <StyledCopyright>{t('footer.copyright')}</StyledCopyright>
          </div>
        </StyledSmallContainer>
    );
}

//---------------For Desktop----------------
const StyledLink = styled(NavLink)`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: white;
  margin-right: 20px;
  text-decoration: none;
`;

const StyledContainer = styled.div`
  /* border: 1px solid blue; */
  padding: 50px  10%;
  width: 80%;
  background: #2490C8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  bottom: 0;
`;

const StyledInnerContainer = styled.div`
  /* border: 1px solid blue; */
  width:auto;
  background: #2490C8;
  display: flex;
  align-items: center;
`;
//------------------------------------------

//---------------For Mobile----------------
const StyledSmallContainer = styled.div`
  /* border: 1px solid blue; */
  padding: 50px  10%;
  width: 80%;
  background: #2490C8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  bottom: 0;
  text-align: center;
`;

const StyledInnerSmallContainer = styled.div`
  /* border: 1px solid blue; */
  width:auto;
  background: #2490C8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const StyledSmallLink = styled(NavLink)`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
  text-decoration: none;
`;
//------------------------------------------

const StyledCopyright = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  color: white;
`;

export default Footer;
