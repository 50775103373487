import { forwardRef } from 'react';
import styled from 'styled-components';
import userImg from '../../assets/Practitioner_main01.svg';
import backgroundImg from '../../assets/Practitioner_bg_1920.png';
import calendarImg from '../../assets/Practitioner_small01.png';
import phoneImg from '../../assets/Practitioner_small02.png';
import prescriptionImg from '../../assets/Practitioner_small03.png';
import consultationImg from '../../assets/Practitioner_small04.png';
import ImgTextCard from '../../components/card/imgTextCard';
import TextImgCard from '../../components/card/textImgCard';
// import JoinButton from '../../components/button/join';
import { NavLink } from "react-router-dom";
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';

const PractitionerContainer = forwardRef((props, ref) => {
    const screenSize = MediaQuery().screenSize;
    const { t } = useTranslation('translation');
    return (
        <div>
            {
                //Desktop size
                screenSize == "Desktop" ?
                    <StyledContainer ref={ref}>
                        <StyledCardContainer>
                            <StyledCardInnerContainer>
                                <ImgTextCard image={calendarImg} text={t('pratitioner.description1')} />
                                <ImgTextCard image={prescriptionImg} text={t('pratitioner.description3')} />
                            </StyledCardInnerContainer>
                            <StyledCardInnerContainer>
                                <ImgTextCard image={phoneImg} text={t('pratitioner.description2')} />
                                <ImgTextCard image={consultationImg} text={t('pratitioner.description4')} />
                            </StyledCardInnerContainer>
                        </StyledCardContainer>

                        <StyledImageContainer>
                            <TextImgCard image={userImg} title={t('pratitioner.title')} text={t('pratitioner.question')} />
                            <NavLink to="/privatepolicy">
                                {/* <JoinButton></JoinButton> */}
                            </NavLink>
                        </StyledImageContainer>
                    </StyledContainer>

                    :
                    //Tablet size
                    screenSize == "Tablet" ?
                        <StyledTabletContainer ref={ref}>
                            <StyledImageContainer>
                                <TextImgCard image={userImg} title={t('pratitioner.title')} text={t('pratitioner.question')} />
                            </StyledImageContainer>

                            <div style={{ marginBottom: '10px' }}>
                                <NavLink to="/privatepolicy">
                                    {/* <JoinButton></JoinButton> */}
                                </NavLink>
                            </div>

                            <StyledTabletCardContainer>
                                <StyledCardInnerContainer>
                                    <ImgTextCard image={calendarImg} text={t('pratitioner.description1')} />
                                    <ImgTextCard image={prescriptionImg} text={t('pratitioner.description3')} />
                                </StyledCardInnerContainer>
                                <StyledCardInnerContainer>
                                    <ImgTextCard image={phoneImg} text={t('pratitioner.description2')} />
                                    <ImgTextCard image={consultationImg} text={t('pratitioner.description4')} />
                                </StyledCardInnerContainer>
                            </StyledTabletCardContainer>

                        </StyledTabletContainer>
                        :
                        //Mobile size
                        <StyledMobileContainer ref={ref}>
                            <StyledImageContainer>
                                <TextImgCard image={userImg} title={t('pratitioner.title')} text={t('pratitioner.question')} />
                            </StyledImageContainer>

                            <div style={{ marginBottom: '10px' }}>
                                <NavLink to="/privatepolicy">
                                    {/* <JoinButton></JoinButton> */}
                                </NavLink>
                            </div>

                            <div>
                                <ImgTextCard image={calendarImg} text={t('pratitioner.description1')} />
                                <ImgTextCard image={phoneImg} text={t('pratitioner.description2')} />
                                <ImgTextCard image={prescriptionImg} text={t('pratitioner.description3')} />
                                <ImgTextCard image={consultationImg} text={t('pratitioner.description4')} />
                            </div>
                        </StyledMobileContainer>
            }
        </div>
    );
});

const StyledMobileContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 400px 0 150px 0;
  height: 1500px;
  background: url(${backgroundImg}) no-repeat center center / cover;
  margin-top: -400px;
`;

const StyledTabletContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 300px 5% 150px 5%;
  width:auto;
  height: 800px;
  background: url(${backgroundImg}) no-repeat center center / cover;
  margin-top: -400px;
`;

const StyledTabletCardContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
`;

const StyledContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 5% 150px 5%;
  width:auto;
  height: 100%;
  background: url(${backgroundImg}) no-repeat top center / cover;
  margin-top: -400px;
`;

const StyledImageContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 60%;
`;

const StyledCardContainer = styled.div`
  /* border: 1px solid red; */
  margin-right: 10%;
  display: flex;
`;

const StyledCardInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height:100%;
  /* border: 1px solid red; */
`;

export default PractitionerContainer;
