import { forwardRef } from 'react';
import styled from 'styled-components';
import userImg from '../../assets/user_main01.svg';
import backgroundImg from '../../assets/user_bg_1920.png';
import calendarImg from '../../assets/user_small01.png';
import phoneImg from '../../assets/user_small02.png';
import prescriptionImg from '../../assets/user_small03.png';
import deliveryImg from '../../assets/user_small04.png';
import ImgTextCard from '../../components/card/imgTextCard';
import TextImgCard from '../../components/card/textImgCard';
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';

const UserContainer = forwardRef((props, ref) => {
  const screenSize = MediaQuery().screenSize;
  const { t } = useTranslation('translation');
    return (
      <div>
        { 
        //Desktop size
        screenSize == "Desktop"?
          <StyledContainer ref={ref}>
              <StyledImageContainer>
                  <TextImgCard image={userImg} title={t('user.title')} text={t('user.question')}/>
              </StyledImageContainer>

              <StyledCardContainer>
                <div>
                    <ImgTextCard image={calendarImg} text={t('user.description1')}/>
                    <ImgTextCard image={prescriptionImg} text={t('user.description3')}/>
                </div>
                <div>
                    <ImgTextCard image={phoneImg} text={t('user.description2')}/>
                    <ImgTextCard image={deliveryImg} text={t('user.description4')}/>  
                </div>
              </StyledCardContainer>
          </StyledContainer>
          :
          //Tablet size
          screenSize == "Tablet"?
          <StyledTabletContainer ref={ref}>
            <StyledImageContainer>
                <TextImgCard image={userImg} title={t('user.title')} text={t('user.question')}/>
            </StyledImageContainer>

            <StyledTabletCardContainer>
              <div>
                  <ImgTextCard image={calendarImg} text={t('user.description1')}/>
                  <ImgTextCard image={prescriptionImg} text={t('user.description3')}/>
              </div>
              <div>
                  <ImgTextCard image={phoneImg} text={t('user.description2')}/>
                  <ImgTextCard image={deliveryImg} text={t('user.description4')}/>  
              </div>
            </StyledTabletCardContainer>
          </StyledTabletContainer>
          :
          //Mobile size
          <StyledMobileContainer ref={ref}>
            <StyledImageContainer>
                <TextImgCard image={userImg} title={t('user.title')} text={t('user.question')}/>
            </StyledImageContainer>

            <div>
              <ImgTextCard image={calendarImg} text={t('user.description1')}/>
              <ImgTextCard image={phoneImg} text={t('user.description2')}/>
              <ImgTextCard image={prescriptionImg} text={t('user.description3')}/>
              <ImgTextCard image={deliveryImg} text={t('user.description4')}/>  
            </div>
          </StyledMobileContainer>
          }
      </div>
    );
});

const StyledMobileContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -1;
  position: relative;
  padding: 300px 5% 500px 5%;
  width:auto;
  height: 1500px;
  background: url(${backgroundImg}) no-repeat center center / cover;
`;

const StyledTabletContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -1;
  position: relative;
  padding: 300px 5% 500px 5%;
  width:auto;
  height: 800px;
  background: url(${backgroundImg}) no-repeat center center / cover;
`;

const StyledTabletCardContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
`;


const StyledContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  position: relative;
  padding: 100px 5% 300px 5%;
  width:auto;
  height: 800px;
  background: url(${backgroundImg}) no-repeat center center / cover;
`;

const StyledImageContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 60%;
`;

const StyledCardContainer = styled.div`
  /* border: 1px solid red; */
  margin-left: 10%;
  display: flex;
`;

export default UserContainer;
