import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import { BrowserRouter,Routes ,Route} from "react-router-dom";

import Home from './pages/Home';
import PrivatePolicy from './pages/PrivatePolicy';
import UserTermsAndConditions from './pages/TermsConditions/user';
import PractitionerTermsAndConditions from './pages/TermsConditions/practitioner';
import UserTNC from './pageComponents/StaticContent/UserTNC';
import PractitionerTNC from './pageComponents/StaticContent/PractitionerTNC';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="">
      <BrowserRouter>
        <Routes>
          <Route exact path="/terms_conditions_user_tc" element={<UserTNC requiredLanguage="tc"/>}></Route>
          <Route exact path="/terms_conditions_user" element={<UserTNC requiredLanguage="en"/>}></Route>
          <Route exact path="/terms_conditions_practitioner_tc" element={<PractitionerTNC requiredLanguage="tc"/>}></Route>
          <Route exact path="/terms_conditions_practitioner" element={<PractitionerTNC requiredLanguage="en"/>}></Route>
          <Route exact path="/*" element={<App />}>
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
