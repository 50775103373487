import React, {forwardRef} from 'react'
import styled from 'styled-components';
import award1 from '../../assets/awards/mediazone.png'
import award2 from '../../assets/awards/asia_smart_app.png'
import award3 from '../../assets/awards/etnet_smartcity.png'
import award4 from '../../assets/awards/hkmol.png'
import award5 from '../../assets/awards/social_caring_leadership.png'
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';


const Award = forwardRef((props, ref) => {
  const screenSize = MediaQuery().screenSize
  const { t } = useTranslation('translation');

  return (
    <StyledContainer ref={ref}>
      <StyledTitle>{t('awards.title')}</StyledTitle>
      <StyledAwardContainer screenSize={screenSize} rowAwards={2}>
        <img src={award1} className='award'></img>
        <img src={award2} className='award'></img>
      </StyledAwardContainer>
      <StyledAwardContainer screenSize={screenSize} rowAwards={3}>
          <img src={award3} className='award'></img>
          <img src={award4} className='award'></img>
          <img src={award5} className='award'></img>
      </StyledAwardContainer>
    </StyledContainer>
  )
})
const StyledContainer = styled.div`
  align-items: center;
  margin-top: 100px;
  padding: 0 10%;
  width:auto;
`;
const StyledTitle = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 80px;
  color: #32394C;
`;




const StyledAwardContainer = styled.div`
  margin: auto;
  margin-bottom:  ${(props)=> props.screenSize === 'Mobile' ? '60px' : '100px'};

  display: grid;
  grid-template-columns: ${(props)=> {
    return props.screenSize === 'Mobile' ? 
    `1fr` :
    `repeat(${props.rowAwards ?? 3},1fr)`
  }};
  grid-template-rows: max-content;
  justify-items: center;
  align-items: center;
  gap: ${(props)=> props.screenSize === 'Mobile' ? '60px' : '90px'};
  .award{
    max-width: 100%;
    max-height: 100%;
  }

`


export default Award