import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import CmpLoginButton from '../../components/button/login';
import MediaQuery from "../../hook/MediaQuery";
import { useTranslation } from 'react-i18next';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';
import Cross from '../../assets/drawer_cross.svg';

const Drawer = ({ open, setDrawer }) => {
  const screenSize = MediaQuery().screenSize;
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation');

  const toChinese = () => {
    let pathname = location.pathname;
    let newPathname = pathname.replace('en', 'tc');
    navigate(`${newPathname}`);
    i18n.changeLanguage('tc');
  };

  const toEnglish = () => {
    let pathname = location.pathname;
    let newPathname = pathname.replace('tc', 'en');
    navigate(`${newPathname}`);
    i18n.changeLanguage('en');
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  const list = () => (
    <Box
      sx={{
        width: 250,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <StyledImgDiv>
          <img onClick={toggleDrawer(false)} src={Cross} style={{ cursor: 'pointer' }}></img>
        </StyledImgDiv>
        <List
          sx={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          onClick={toggleDrawer(false)}
        >
          <div>
            <ListItem button component={NavLink} to={`/${i18n.language}/#aboutUs`} key={'aboutUs'}>
              <ListItemText primary={t('nav.aboutUs')} />
            </ListItem>
            <ListItem button component={NavLink} to={`/${i18n.language}/#forUser`} key={'forUser'}>
              <ListItemText primary={t('nav.forUser')} />
            </ListItem>
            <ListItem button component={NavLink} to={`/${i18n.language}/#forPractitioner`} key={'forPractitioner'}>
              <ListItemText primary={t('nav.forPractitioner')} />
            </ListItem>
            <ListItem button component={NavLink} to={`/${i18n.language}/#award`} key={'award'}>
              <ListItemText primary={t('nav.award')} />
            </ListItem>
            <ListItem button component={NavLink} to={`/${i18n.language}/#faq`} key={'faq'}>
              <ListItemText primary={t('nav.faq')} />
            </ListItem>
            <ListItem button component={NavLink} to={`/${i18n.language}/#download`} key={'download'}>
              <ListItemText primary={t('nav.download')} />
            </ListItem>
            {/* <ListItem button component={NavLink} to="/cmpLogin" key={'cmpLogin'}>
                <CmpLoginButton></CmpLoginButton>
            </ListItem> */}
          </div>
        </List>
      </div>
      <StyledFlexContainer>
        <ListItem button key={'Eng'} onClick={() => toEnglish()} style={{ borderRight: "1px solid white" }}>
          <ListItemText
            primaryTypographyProps={{
              style: {
                textAlign: 'center',
                fontWeight: i18n.language == "en" ? 700 : 300
              }
            }}
            primary={"Eng"} />
        </ListItem>
        <ListItem button key={'Tc'} onClick={() => toChinese()}>
          <ListItemText
            primaryTypographyProps={{
              style: {
                textAlign: 'center',
                fontWeight: i18n.language == "tc" ? 700 : 300
              }
            }}
            primary={'中'} />
        </ListItem>
      </StyledFlexContainer>
    </Box>
  );

  return (
    <div>
      <MuiDrawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#2590C8",
            color: "white",
            margin: 0,
            padding: 0,
          }
        }}
      >
        {list()}
      </MuiDrawer>
    </div>
  );
};

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1872A1;
`;

const StyledImgDiv = styled.div`
  padding: 15px;
  text-align: right;
`;

export default Drawer;
