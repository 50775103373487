import { React, useState } from 'react';
import Headphone from "../../assets/contact.png";
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import Cross from '../../assets/drawer_cross.svg';
import Staff from '../../assets/contactIcon.png';
import MediaQuery from "../../hook/MediaQuery";
import { useTranslation } from 'react-i18next';


function ContactButton() {
    const [open, setOpen] = useState(false);
    const [openCall, setOpenCall] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const screenSize = MediaQuery().screenSize;
    const { t } = useTranslation('translation');

    const contactNumber = `+852 6075 6081`;
    const emailAddress = `cs@ingoodhealth.online`;
  
    const handleOpen = () => {
      if(open){
        setOpen(false);
        setOpenCall(false);
        setOpenEmail(false);
      } else{
        setOpen(true)
      }
    };

    const handleOpenCall = () => {
      if(openCall){
        setOpen(false);
        setOpenCall(false);
        setOpenEmail(false);
      } else{
        setOpenCall(true)
      }
    };

    const handleOpenEmail = () => {
      if(openEmail){
        setOpen(false);
        setOpenCall(false);
        setOpenEmail(false);
      } else{
        setOpenEmail(true)
      }
    };
  
    return (
      <StyledDiv>
        <StyledDialog>
            {
              open && (
                <Paper
                  sx={{
                    borderRadius: '10px',
                  }}
                >  
                    <List
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <ListItem button onClick={(e) => {e.stopPropagation(); handleOpenCall();}} key={'call'}>
                            <ListItemText primary={t("common.call")} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={(e) => {e.stopPropagation(); handleOpenEmail();}} button key={'emailCs'}>
                            <ListItemText primary={t("common.emailCs")} />
                        </ListItem>
                    </List>
                </Paper>
              )
          }
        </StyledDialog>

        {
          (openCall || openEmail) && (
              screenSize=="Desktop"?
                <StyledInfoDialog>
                  <StyledInfoHeader>
                    {openCall && <StyledInfoTitle>{t("common.call")}</StyledInfoTitle>}
                    {openEmail && <StyledInfoTitle>{t("common.emailCs")}</StyledInfoTitle>}
                    <StyledInfoCrossImg 
                      src={Cross}
                      onClick={() => handleOpen()}
                    >
                    </StyledInfoCrossImg>
                  </StyledInfoHeader>

                  <StyledInfoContent>
                    <StyledInfoStaffImg src={Staff}></StyledInfoStaffImg>
                    {openCall && <StyledInfoInnerContent>{contactNumber}</StyledInfoInnerContent>}
                    {openEmail && <StyledInfoInnerContent>{emailAddress}</StyledInfoInnerContent>}
                  </StyledInfoContent>
                </StyledInfoDialog>
                :
                <StyledMobileInfoDiv>
                  <StyledInfoHeader>
                    {openCall && <StyledInfoTitle>{t("common.call")}</StyledInfoTitle>}
                    {openEmail && <StyledInfoTitle>{t("common.emailCs")}</StyledInfoTitle>}
                    <StyledInfoCrossImg 
                      src={Cross}
                      onClick={() => handleOpen()}
                    >
                    </StyledInfoCrossImg>
                  </StyledInfoHeader>

                  <StyledMobileInfoContent>
                    <StyledMobileInfoStaffImg src={Staff}></StyledMobileInfoStaffImg>
                    {openCall && <StyledInfoInnerContent>{contactNumber}</StyledInfoInnerContent>}
                    {openEmail && <StyledInfoInnerContent>{emailAddress}</StyledInfoInnerContent>}
                  </StyledMobileInfoContent>
                </StyledMobileInfoDiv>
          )
        }


        <div type="button" onClick={() => {handleOpen();}}>
          <StyledImgDiv src={Headphone}></StyledImgDiv>
        </div>
      </StyledDiv>
    );
  }

const StyledDiv = styled.div`
    position: fixed;
    bottom: 73px;
    right: -5px;
`;

const StyledDialog = styled.div`
    position: fixed;
    bottom: 130px;
    right: 70px;
`;

const StyledMobileInfoDiv = styled.div`
  color: white;
  height: 100%;
  position: fixed;
  bottom: 125px;
  right: 70px;
  background: #384BB8;
  border-radius: 10px;
  height: 300px;
  width: 200px;
  min-width: 200px;
  padding: 20px;
`;

const StyledMobileInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: 70%;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledMobileInfoStaffImg = styled.img`
  height: 100px;
`;


const StyledInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30%;
`;

const StyledInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 24px;
`;

const StyledInfoContent = styled.div`
  display: flex;
  font-size: 18px;
  height: 70%;
  align-items: center;
`;

const StyledInfoInnerContent = styled.div`
  display: flex;
  font-size: 18px;
  height: 70%;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  width: 100%;
  text-align: center;
  white-space: normal;
`;

const StyledInfoCrossImg = styled.img`
  height: 20px;
  cursor: pointer;
`;

const StyledInfoStaffImg = styled.img`
  height: 70px;
`;

const StyledInfoDialog = styled.div`
  color: white;
  height: 100%;
  position: fixed;
  bottom: 125px;
  right: 70px;
  background: #384BB8;
  border-radius: 10px;
  height: 150px;
  width: 400px;
  padding: 20px;
`;

const StyledImgDiv = styled.img`
  height: 85px;
  width: 85px;
  cursor: pointer;
`;
  
  
  export default ContactButton;
  