import Nav from '../components/navbar';
import React, { useRef } from 'react';
import styled from 'styled-components';
import Footer from "../pageComponents/Home/footer";
import ContactButton from "../components/button/contact";
import BackToTopButton from '../components/button/backToTop';

export const withNavBar = (Component) => ({ ...props }) => {
    const aboutUsRef = useRef(null);
    const forUserRef = useRef(null);
    const forPractitionerRef = useRef(null);
    const faqRef = useRef(null);
    const awardRef = useRef(null);
    const downloadRef = useRef(null);
    const homeRef = useRef(null);
    const refs = { aboutUsRef, forUserRef, forPractitionerRef, faqRef, awardRef, downloadRef, homeRef };

    return (
        <div>
            <Nav refs={refs} />
            <Component refs={refs} {...props} />
            <Footer></Footer>
            <ContactButton></ContactButton>
            <BackToTopButton></BackToTopButton>
        </div>
    )
}
