import { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const HtmlEmbed = ({ src }) => {
  const [width, height] = useWindowSize();
  const [iFrameHeight, setiFrameHeight] = useState("0px");
  const iframeRef = useRef(null);

  useLayoutEffect(() => {
    const obj = ReactDOM.findDOMNode(iframeRef.current);
    obj.style.height = 0;
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 200 + 'px';
  }, [width, height])

  return (
    <IframeWrapper
      src={src}
      onLoad={() => {
        const obj = ReactDOM.findDOMNode(iframeRef.current);
        obj.style.height = obj.contentWindow.document.body.scrollHeight + 200 + 'px';
      }}
      ref={iframeRef}
      scrolling="no"
      frameBorder="0"
    >
    </IframeWrapper>
  );
}

const IframeWrapper = styled.iframe`
    /* border: 1px solid red; */
    width: 100%;
    margin: 50px 0;
`;

export default HtmlEmbed;
