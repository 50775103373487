import React from 'react';
import styled from 'styled-components';
import mainImage from '../../assets/welcome_image.png';
import mainBackground from '../../assets/main_bg_1920.png';
import mainSmallBackground from '../../assets/main_bg_1224.png';
import MediaQuery from "../../hook/MediaQuery";
import { useTranslation } from 'react-i18next';

function MainContainer() {
  const screenSize = MediaQuery().screenSize;
  const { t } = useTranslation('translation');
  return (
    <div>
      {
        screenSize == "Desktop" ?
          <StyledContainer>
            <StyledImageContainer>
              <StyledImage src={mainImage}></StyledImage>
            </StyledImageContainer>

            <StyledTextContainer>
              <StyledTitle1>
                {t('main.welcome')}
              </StyledTitle1>
              <StyledTitle2>
                {t('main.name')}
              </StyledTitle2>
              <StyledContent>
                {t('main.description')}
              </StyledContent>
            </StyledTextContainer>
          </StyledContainer>
          :
          <StyledSmallContainer>
            <StyledSmallImageContainer>
              <StyledSmallImage src={mainImage}></StyledSmallImage>
            </StyledSmallImageContainer>

            <StyledSmallTextContainer>
              <StyledSmallTitle1>
                {t('main.welcome')}
              </StyledSmallTitle1>
              <StyledSmallTitle2>
                {t('main.name')}
              </StyledSmallTitle2>
              <StyledSmallContent>
                {t('main.description')}
              </StyledSmallContent>
            </StyledSmallTextContainer>
          </StyledSmallContainer>
      }
    </div>
  );
}

const StyledSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  width:auto;
  background: url(${mainSmallBackground});
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 100px;
`;

const StyledSmallImageContainer = styled.div`
  width: 80%;
`;

const StyledSmallImage = styled.img`
  height: 100%;
  width: 100%;
`;

const StyledSmallTextContainer = styled.div`
  height: 100%;
  width: 80%;
  color: white;
  margin-bottom: 250px;
`;

const StyledSmallTitle1 = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledSmallTitle2 = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
`;

const StyledSmallContent = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 7%;
  width:auto;
  background: url(${mainBackground});
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledImageContainer = styled.div`
  width: 50%;
`;

const StyledImage = styled.img`
  height: 50%;
  width: 100%;
`;

const StyledTextContainer = styled.div`
  height: 100%;
  width: 50%;
  color: white;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
`;

const StyledTitle1 = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  white-space: nowrap;
`;

const StyledTitle2 = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  white-space: nowrap;
`;

const StyledContent = styled.div`
  /* font-family: SF Pro Display; */
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

export default MainContainer;
