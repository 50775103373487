import { forwardRef } from 'react'; 
import i18next from 'i18next';
import styled from 'styled-components';
import OneStopAppImg from '../../assets/one_stop_app.png';
import HipaaImg from '../../assets/hipaa.png';
import QualityMedicalServiceImg from '../../assets/quality_medical_service.png';
import AboutUsCard from '../../components/card/aboutUsCard';
import MediaQuery from "../../hook/MediaQuery";
import YoutubeEmbed from "../../components/iframe/youtubeEmbed";
import { useTranslation } from 'react-i18next';


const MainContainer = forwardRef((props, ref) => {
    const screenSize = MediaQuery().screenSize;
    const { t } = useTranslation('translation');
    return (
        <StyledContainer ref={ref}>
            <StyledTitle>{t('aboutUs.aboutUs')}</StyledTitle>

            {/* Desktop Size */}
            {
                screenSize == "Desktop" &&
                <StyledCardsMaxContainer>
                    <AboutUsCard 
                        image={OneStopAppImg} 
                        title={t('aboutUs.title1')} 
                        text={t('aboutUs.description1')}
                    />
                    <AboutUsCard 
                        image={HipaaImg} 
                        title={t('aboutUs.title2')} 
                        text={t('aboutUs.description2')}
                    />
                    <AboutUsCard 
                        image={QualityMedicalServiceImg} 
                        title={t('aboutUs.title3')} 
                        text={t('aboutUs.description3')}
                    />
                </StyledCardsMaxContainer>
            }

            {/* Tablet and Mobile Size */}
            {
                (screenSize == "Tablet" || screenSize == "Mobile") &&
                <StyledCardsSmallContainer>
                    <AboutUsCard 
                        image={OneStopAppImg} 
                        title={t('aboutUs.title1')} 
                        text={t('aboutUs.description1')}
                    />
                    <AboutUsCard 
                        image={HipaaImg} 
                        title={t('aboutUs.title2')} 
                        text={t('aboutUs.description2')}
                    />
                    <AboutUsCard 
                        image={QualityMedicalServiceImg} 
                        title={t('aboutUs.title3')} 
                        text={t('aboutUs.description3')}
                    />
                </StyledCardsSmallContainer>
            }
            
            {
                <div>
                    <StyledTextContainer>
                    {t('aboutUs.details.0')}
                    </StyledTextContainer>
                    <StyledTextContainer>
                    {t('aboutUs.details.1')}
                    </StyledTextContainer>
                </div>
            }

            {
                <StyledQuoteContainer>
                    <StyledQuoteContentContainer>
                        <StyledQuoteSymbol>"</StyledQuoteSymbol>
                        <StyledUpperQuoteText>{t('aboutUs.sloganTop')}</StyledUpperQuoteText>
                    </StyledQuoteContentContainer>
                    <StyledQuoteContentContainer>
                        <StyledBottomQuoteText>{t('aboutUs.sloganBottom')}</StyledBottomQuoteText>
                        <StyledQuoteSymbol>"</StyledQuoteSymbol>
                    </StyledQuoteContentContainer>
                </StyledQuoteContainer>
            }

            {/* Embed Youtube video here */}
            <StyledVideoContainer>
                <YoutubeEmbed embedId="pWL3Zx8An1o" />
            </StyledVideoContainer>

        </StyledContainer>
    );
});

//---------------For Desktop----------------
const StyledCardsMaxContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 58px;
`;
//------------------------------------------

//---------------For Mobile----------------
const StyledCardsSmallContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 58px;
    align-items: center;
`;
//------------------------------------------

const StyledTitle = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 100px;
  color: #32394C;
`;

const StyledContainer = styled.div`
  /* border: 1px solid blue; */
  align-items: center;
  padding: 0 5%;
  width:auto;
`;

const StyledTextContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    /* font-family: SF Pro Display; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-bottom: 50px;
    padding: 0 5%;
    color: #5F6872;
`;

const StyledQuoteContainer = styled.div`
    /* border: 1px solid green; */
    margin-bottom: 50px;
    color: #5F6872;
`;

const StyledQuoteContentContainer = styled.div`
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    justify-content:center;
`;

const StyledQuoteSymbol = styled.div`
    font-family: Arial;
    font-size: 62.73px;
    font-style: italic;
    font-weight: 400;
    /* border: 1px solid blue; */
    display: flex;
    height: 40px;
    letter-spacing: 10px;
`;

const StyledUpperQuoteText = styled.div`
/* font-family: SF Pro Display; */
    font-size: 20.91px;
    font-style: italic;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    text-align: center;
    /* border: 1px solid orange; */
    margin-right: 50px;
`;

const StyledBottomQuoteText = styled.div`
/* font-family: SF Pro Display; */
    font-size: 20.91px;
    font-style: italic;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    text-align: center;
    /* border: 1px solid orange; */
    margin-left: 50px;
`;

const StyledVideoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 100px 0;
`;


export default MainContainer;
