import { forwardRef } from 'react';
import styled from 'styled-components';
import UserImg from '../../assets/user_small.svg';
import PractitionerImg from '../../assets/Practitioner_small.svg';
import PractitionerSearchImg from '../../assets/Practitioner_search.svg';
import UserSearchImg from '../../assets/user_search.svg';
import DownloadCard from '../../components/card/downloadCard';
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';

const DownloadContainer = forwardRef((props, ref) => {
    const screenSize = MediaQuery().screenSize;
    const { t } = useTranslation('translation');
    return (
        <div style={{ marginTop: '100px' }}>
        { 
        screenSize == "Desktop"?
            <StyledContainer  ref={ref}>
                <StyledTitle>{t('download.title')}</StyledTitle>

                {/* <div style={{  marginBottom: '50px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <hr style={{width:'35%'}} />
                    <StyledComingSoon>{t('common.comingSoon')}</StyledComingSoon>
                    <hr style={{width:'35%'}} />
                </div> */}

                <StyledCardsContainer>
                    <DownloadCard 
                        image={UserImg}
                        title={t('download.user')}
                        searchImg={UserSearchImg}
                    />
                    <DownloadCard 
                        image={PractitionerImg}
                        title={t('download.practitioner')}
                        searchImg={PractitionerSearchImg}
                    />
                </StyledCardsContainer>
                
            </StyledContainer>
        :
            <StyledContainer  ref={ref}>
                <StyledSmallTitle>{t('download.title')}</StyledSmallTitle>

                {/* <div style={{  marginBottom: '50px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <hr style={{width:'35%'}} />
                        <StyledMobileComingSoon>{t('common.comingSoon')}</StyledMobileComingSoon>
                    <hr style={{width:'35%'}} />
                </div> */}

                <StyledSmallCardsContainer>
                    <DownloadCard 
                        image={UserImg}
                        title={t('download.user')}
                        searchImg={UserSearchImg}
                    />
                    <DownloadCard 
                        image={PractitionerImg}
                        title={t('download.practitioner')}
                        searchImg={PractitionerSearchImg}
                    />
                </StyledSmallCardsContainer>
            
            </StyledContainer>
        }
        </div>
    );
});

const StyledSmallTitle = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
  color: #32394C;
`;

const StyledTitle = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 50px;
  white-space: nowrap;
  color: #32394C;
`;

const StyledContainer = styled.div`
  /* border: 1px solid blue; */
  align-items: center;
  padding: 0 10%;
  width:auto;
`;

const StyledCardsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 58px;
`;

const StyledSmallCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 58px;
    margin-left: 50px;
    align-items: center;
`;

const StyledMobileComingSoon = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  font-style: italic;
  margin: 0 10px;
  line-height: 42px;
  text-transform: uppercase;
  text-align: center;
  color: #32394C;
`;

const StyledComingSoon = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  font-style: italic;
  white-space: nowrap;
  margin: 0 10px;
  line-height: 42px;
  text-transform: uppercase;
  color: #32394C;
`;

export default DownloadContainer;
