import styled from 'styled-components';
import MediaQuery from '../../hook/MediaQuery';

const ImgTextCard = function({image, text}) {
    const screenSize = MediaQuery().screenSize;
    return (
        <div>
            { 
            // Desktop
            screenSize == "Desktop" ?
            <StyledContainer>
                <StyledImageContainer>
                    <StyledImage src={image}></StyledImage>
                </StyledImageContainer>

                <StyledCardText>{text}</StyledCardText>
            </StyledContainer>
            :
            // Tablet
            screenSize == "Tablet" ?
            <StyledTabletContainer>
                <StyledImageContainer>
                    <StyledImage src={image}></StyledImage>
                </StyledImageContainer>

                <StyledCardText>{text}</StyledCardText>
            </StyledTabletContainer>
            :
            // Mobile
            <StyledMobileContainer>
                <StyledImageContainer>
                    <StyledImage src={image}></StyledImage>
                </StyledImageContainer>

                <StyledCardText>{text}</StyledCardText>
            </StyledMobileContainer>
            }
        </div>
    );
}

const StyledMobileContainer = styled.div`
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTabletContainer = styled.div`
  margin: 0 50px;
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  height: 250px;
  width: 350px;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 150px;
  width: 300px;
  /* border: 1px solid red; */
`;

const StyledImage = styled.img`
    /* border: 1px solid green; */
    width: 168px;
    /* height: 100%; */
`;

const StyledCardText = styled.div`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    /* border: 1px solid blue; */
    color: #5F6872;
    width: 250px;
`;

export default ImgTextCard;
