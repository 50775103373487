import React from 'react';
import MainContainer from "../../pageComponents/Home/mainSession";
import AboutUsContainer from "../../pageComponents/Home/aboutUsSession";
import UserContainer from "../../pageComponents/Home/userSession";
import PractitionerContainer from "../../pageComponents/Home/practitionerSession";
import DownloadContainer from "../../pageComponents/Home/downloadSession";
import Award from '../../pageComponents/Home/Award.jsx'
import FAQ from "../../pageComponents/Home/faq";

const Home = ({ refs }) => {

    return (
        <div ref={refs.homeRef}>
            <MainContainer />
            <AboutUsContainer ref={refs.aboutUsRef} />
            <UserContainer ref={refs.forUserRef} />
            <PractitionerContainer ref={refs.forPractitionerRef} />
            <Award ref={refs.awardRef} />
            <FAQ ref={refs.faqRef} />
            <DownloadContainer ref={refs.downloadRef} />
        </div>
    );
}

export default Home;
