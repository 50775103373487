import styled from 'styled-components';
import mainBackground from '../../assets/privacy_policy_bg.png';
import HtmlEmbed from '../../components/iframe/htmlEmbed';
import { useTranslation } from 'react-i18next';
import UserTNC from '../../pageComponents/StaticContent/UserTNC';

const TermsAndConditions = () => {
  const { i18n, t } = useTranslation('translation');
  let lang = i18n.language;
  return (
    <Wrapper>
      <TNCWrapper>
        <UserTNC />
      </TNCWrapper>
    </Wrapper>
  );
}
const TNCWrapper = styled.div`
  margin-top:50px;
  margin-bottom:200px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  width: 80%;
  height: 100%;
  background: url(${mainBackground});
  background-size: contain;
  background-repeat: no-repeat;
  overflow: auto;
  padding: 0 10%;
`;


export default TermsAndConditions;
