import { useState, useEffect, forwardRef, useContext } from 'react';
import styled from 'styled-components';
import { Collapse } from '@mui/material';
import FaqBg from '../../assets/faq_bg.png';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getFAQ } from '../../network/systemconfig';

const FAQ = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [faq,setFaq] = useState({})
    const location = useLocation();
    const { i18n, t } = useTranslation('translation');
    let lang = i18n.language;
    useEffect(async ()=>{
        try{
            const data = await getFAQ()
            setFaq(data.data)
        }catch(err){
            setFaq({})
        }
    },[])
    useEffect(() => {
        if (location.hash == "#faq") {
            setOpen(true);
        }
    }, [location.hash]);

    return (
        <div ref={ref} onClick={() => setOpen(!open)}>
            <StyledContainer>
                <StyledHeader>
                    {t("faq.title")} {open ? '-' : '+'}
                </StyledHeader>
                <Collapse in={open}>
                    <StyledFAQ dangerouslySetInnerHTML={{ __html: lang === 'tc' ? faq?.contentChinese : faq?.contentEnglish }} className="faq" />
                </Collapse>
            </StyledContainer>
        </div>
    );
});

const StyledFAQ = styled.div`
    h4{
        color: white;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;
        margin-top:50px;
    }
    p{
        color: white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    
    }
    a{
        color: #324492;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;}

`
const StyledContainer = styled.div`
  /* border: 1px solid red; */
  font-size: 16px;
  font-style: normal;
  color: white;
  padding: 0 10%;
  background: url(${FaqBg}) no-repeat center center / cover;;
  padding: 50px 10%;
`;

const StyledHeader = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* font-family: SF Pro Display; */
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
`;






export default FAQ;
