import styled from 'styled-components';
import AppStoreImg from '../../assets/appstore.svg';
import GooglePlayImg from '../../assets/googleplay.svg';
import MediaQuery from '../../hook/MediaQuery';
import { useTranslation } from 'react-i18next';

const DownloadCard = function ({ image, title, searchImg }) {
    const screenSize = MediaQuery().screenSize;
    const { t, i18n } = useTranslation('translation');
    // console.log("i18n", i18n.language);
    return (
        <div>
            {
                screenSize == "Desktop" ?
                    <StyledContainer>
                        {
                            title === t("download.user") ?
                                <div>
                                    <StyledImageContainer title={t("download.user")}>
                                        <StyledCircleImage src={image}></StyledCircleImage>
                                    </StyledImageContainer>
                                    <StyledCard title={t("download.user")}>
                                        <StyledCardTitle>{title}</StyledCardTitle>
                                        <a href={i18n.language === "tc" ? "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-in-good-health/id1559170816?l=zh" : "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-in-good-health/id1559170816?l=en"}>
                                            <StyledImage src={AppStoreImg}></StyledImage>
                                        </a>
                                        <a href={i18n.language === "tc" ? "https://play.google.com/store/apps/details?id=com.project.dolitepat&hl=zh-hk" : "https://play.google.com/store/apps/details?id=com.project.dolitepat&hl=en"}>
                                            <StyledImage src={GooglePlayImg}></StyledImage>
                                        </a>
                                        <img style={{ padding: '10px', marginTop: '20px' }} src={searchImg}></img>
                                    </StyledCard>
                                </div>
                                :
                                <div>
                                    <StyledImageContainer title={t("download.practitioner")}>
                                        <StyledCircleImage src={image}></StyledCircleImage>
                                    </StyledImageContainer>
                                    <StyledCard title={t("download.practitioner")}>
                                        <StyledCardTitle>{title}</StyledCardTitle>
                                        <a href={i18n.language === "tc" ? "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-%E5%B0%88%E6%A5%AD%E7%89%88/id1559160939?l=zh" : "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-%E5%B0%88%E6%A5%AD%E7%89%88/id1559160939?l=en"}>
                                            <StyledImage src={AppStoreImg}></StyledImage>
                                        </a>
                                        <a href={i18n.language === "tc" ? "https://play.google.com/store/apps/details?id=com.project.drdolittle&hl=zh-hk" : "https://play.google.com/store/apps/details?id=com.project.drdolittle&hl=en"}>
                                            <StyledImage src={GooglePlayImg}></StyledImage>
                                        </a>
                                        <img style={{ padding: '10px', marginTop: '20px' }} src={searchImg}></img>
                                    </StyledCard>
                                </div>
                        }
                    </StyledContainer>
                    :
                    <StyledContainer>
                        {
                            title === t("download.user") ?
                                <div>
                                    <StyledImageContainer title={t("download.user")}>
                                        <StyledCircleImage src={image}></StyledCircleImage>
                                    </StyledImageContainer>
                                    <StyledSmallCard title={t("download.user")}>
                                        <StyledCardTitle>{title}</StyledCardTitle>
                                        <a href={i18n.language === "tc" ? "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-in-good-health/id1559170816?l=zh" : "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-in-good-health/id1559170816?l=en"}>
                                            <StyledImage src={AppStoreImg}></StyledImage>
                                        </a>
                                        <a href={i18n.language === "tc" ? "https://play.google.com/store/apps/details?id=com.project.dolitepat&hl=zh-hk" : "https://play.google.com/store/apps/details?id=com.project.dolitepat&hl=en"}>
                                            <StyledImage src={GooglePlayImg}></StyledImage>
                                        </a>
                                        <img style={{ padding: '10px', marginTop: '20px', width: '230px' }} src={searchImg}></img>
                                    </StyledSmallCard>
                                </div>
                                :
                                <div>
                                    <StyledImageContainer title={t("download.practitioner")}>
                                        <StyledCircleImage src={image}></StyledCircleImage>
                                    </StyledImageContainer>
                                    <StyledSmallCard title={t("download.practitioner")}>
                                        <StyledCardTitle>{title}</StyledCardTitle>
                                        <a href={i18n.language === "tc" ? "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-%E5%B0%88%E6%A5%AD%E7%89%88/id1559160939?l=zh" : "https://apps.apple.com/hk/app/%E9%96%8B%E5%BF%83%E9%86%AB%E9%A4%A8-%E5%B0%88%E6%A5%AD%E7%89%88/id1559160939?l=en"}>
                                            <StyledImage src={AppStoreImg}></StyledImage>
                                        </a>
                                        <a href={i18n.language === "tc" ? "https://play.google.com/store/apps/details?id=com.project.drdolittle&hl=zh-hk" : "https://play.google.com/store/apps/details?id=com.project.drdolittle&hl=en"}>
                                            <StyledImage src={GooglePlayImg}></StyledImage>
                                        </a>
                                        <img style={{ padding: '10px', marginTop: '20px', width: '230px' }} src={searchImg}></img>
                                    </StyledSmallCard>
                                </div>
                        }

                    </StyledContainer>
            }
        </div>
    );
}

const StyledSmallCard = styled.div`
  background: ${props => props.title === 'User' || props.title === '用戶' ? "#FFF0F1" : "#F4FAFF"};
  width: 223px;
  height: 350px;
  border-radius: 30px;
  padding: 17px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  margin-bottom: 100px;
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  /* border: 1px solid red; */
  border-radius: 100px;
  background: ${props => props.title === 'User' || props.title === '用戶' ? "#FF7E88" : "#4064C0"};
  z-index: 1;
  position: relative;
  margin-left: -50px;
`;

const StyledCircleImage = styled.img`
    top:0;
    position: absolute;
    border-radius: 100px;
    width: 100px;
    overflow: hidden;
    padding-top: 14px;
`;

const StyledImage = styled.img`
    margin-top: 20px;
    cursor: pointer;
`;

const StyledCard = styled.div`
  background: ${props => props.title == 'User' || props.title === '用戶' ? "#FFF0F1" : "#F4FAFF"};
  width: 323px;
  height: 386px;
  border-radius: 30px;
  padding: 17px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardTitle = styled.div`
    /* font-family: SF Pro Display; */
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 20px;
    color: #32394C;
`;

const StyledCardText = styled.div`
    /* font-family: SF Pro Display; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
`;

export default DownloadCard;
