import { instance } from "./";

const apis = {
  staticContent: "systemConfig/staticContent",
  faq: "systemConfig/faq",
};

export const getStaticContent = async () => {
  const response = await instance.get(`${apis.staticContent}`);
  return response.data;
};
export const getFAQ = async () => {
  const response = await instance.get(`${apis.faq}`);
  return response.data;
};

export default null
