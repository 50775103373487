import i18next from 'i18next';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getStaticContent } from '../../network/systemconfig'

function UserTNC(props) {
  const { i18n, t } = useTranslation('translation');
  const { requiredLanguage } = props
  const enFailed = i18n.getFixedT('en')('tnc.failed')
  const tcFailed = i18n.getFixedT('tc')('tnc.failed')
  let lang = requiredLanguage || i18n.language;
  const [systemConfig, setSystemConfig] = useState()
  useEffect(async () => {
    try {
      const data = await getStaticContent()
      setSystemConfig(data)
    }
    catch (err) {
      setSystemConfig({ userTermsChinese: tcFailed, userTermsEnglish: enFailed })
    }
  }, [])

  return (
    <StyledTNC dangerouslySetInnerHTML={{ __html: lang === 'tc' ? systemConfig?.userTermsChinese : systemConfig?.userTermsEnglish }} className="tnc" />
  )
}

const StyledTNC = styled.div`
  //work around to match the hardcoded version
  -webkit-font-smoothing: initial;
  h1:first-child{
    margin-top:200px;
  };
  h1:last-of-type{
    margin-bottom:50px;
  };
  font-family:initial;
  ol{
    list-style-type:lower-alpha;
  };
  ol > ol{
    list-style-type:lower-roman;
  };
`

export default UserTNC